import { Location } from "@/interfaces/Lesson";
import Moment from "@/services/moment";

export enum LOCATIONS {
  ONLINE = "online",
  PUBLIC = "public",
  HOME = "home",
}

export enum LocationsReadable {
  online = "Nettundervisning",
  public = "Offentlig sted",
  home = "Hjemmeundervisning",
}

export function stringTimeToUnix(date: string, time: string): number {
  const _date = Moment(date).format("YYYY-MM-DD");
  return Moment(`${_date} ${time}`, "YYYY-MM-DD HH:mm").unix();
}

export function mapLocation(location: string): string {
  return LocationsReadable[location as Location];
}
