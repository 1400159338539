





















































import CancelLessonDialog from "@/components/lessons/CancelLessonDialog.vue";
import LessonCalendar from "@/components/lessons/LessonCalendar.vue";
import { userModule } from "@/store/modules/user";
import useAsyncData from "@/hooks/useAsyncData";

import {
  defineComponent,
  onMounted,
  provide,
  reactive,
} from "@vue/composition-api";
import { getLessons } from "@/api/lessonService";
import RegisterLessonDialog from "@/components/lessons/RegisterLessonDialog.vue";
import Lesson, { CreateLesson } from "@/interfaces/Lesson";
import { getActiveProjects } from "@/api/projectService";
import { ActiveProject } from "@/interfaces/Project";
import { LOCATIONS } from "@/helpers/lessonUtils";
import LessonCardSeller from "@/components/lessons/LessonCardSeller.vue";

const emptyLesson: CreateLesson = {
  customerUID: "",
  endTime: 0,
  location: LOCATIONS.ONLINE,
  projectID: "",
  sellerUID: "",
  startTime: 0,
};

export default defineComponent({
  name: "LessonsSeller",
  components: { LessonCalendar, RegisterLessonDialog, CancelLessonDialog, LessonCardSeller },
  setup() {
    const vm = reactive({
      lesson: {
        ID: ""
      } as Lesson,
      projects: [] as ActiveProject[],
      showRegisterLessonDialog: false,
      showCancelLessonDialog: false,
      showLessonCard: false,
    });

    const { isLoading, data: lessons, refresh } = useAsyncData(() =>
      getLessons("seller", userModule.state.userId)
    );

    onMounted(async() => {
      vm.projects = await getActiveProjects("seller", userModule.state.userId);
    });

    function showLessonCard(lesson: Lesson): void {
      vm.lesson = lesson;
      vm.showLessonCard = true;
    }

    function showEditLessonDialog(lesson: Lesson): void {
      vm.lesson = lesson;
      vm.showRegisterLessonDialog = true;
      vm.showLessonCard = false;
    }

    function showRegisterLessonDialog(): void {
      vm.lesson = emptyLesson as Lesson;
      vm.showRegisterLessonDialog = true;
      vm.showLessonCard = false;
    }

    function showCancelLessonDialog(lesson: Lesson): void {
      vm.lesson = lesson;
      vm.showCancelLessonDialog = true;
      vm.showLessonCard = false;
    }

    provide("getLessons", refresh);
    provide("showEditLessonDialog", showEditLessonDialog);
    provide("showCancelLessonDialog", showCancelLessonDialog);
    provide("showLessonCard", showLessonCard);

    return {
      isLoading,
      lessons,
      showEditLessonDialog,
      showRegisterLessonDialog,
      vm,
    };
  },
});
